<template>
  <div class="container" :style="hide ? 'display: none' : 'display: block'">
    <van-image width="80px" src="images/ch_logo.png"/>
    <div class="login-body">
      <van-form @submit="onSubmit">
        <van-field
            v-model="form.username"
            autocomplete="off"
            name="username"
            label="用户名"
            type="text"
            left-icon="manager"
            placeholder="用户名或手机号码"
            :rules="[{ required: true}]"/>
        <van-field
            v-model.trim="form.password"
            autocomplete="off"
            name="password"
            :type="formType"
            label="密码"
            left-icon="lock"
            :right-icon="showPwdIcon"
            placeholder="密码"
            @click-right-icon="onShowPwdIcon"
            :rules="[{ required: true}]"/>
        <van-field
            v-model.trim="form.validateCode"
            autocomplete="off"
            name="validateCode"
            type="text"
            maxlength="4"
            label="验证码"
            left-icon="card"
            placeholder="验证码"
            :rules="[{ required: true}]">
          <template #button>
            <verification-code :key="key" @click="refreshValidateCode"></verification-code>
          </template>
        </van-field>

        <van-checkbox style="margin: 15px" v-model="form.rememberMe" shape="square">自动登录</van-checkbox>

        <div style="margin: 30px;">
          <van-button round block :loading="loginLoading" type="info" native-type="submit">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import VerificationCode from "@/components/common/VerificationCode";
import Cookies from "js-cookie";
import {decrypt, encrypt} from "@/util/jsencrypt";
import {Dialog, Toast} from "vant";

export default {
  name: "login",
  components: {VerificationCode},
  data() {
    return {
      form: {
        username: '',
        password: '',
        rememberMe: false,
        validateCode: ''
      },
      key: 0,
      tempCode: '',
      showPwdIcon: 'closed-eye',
      formType: 'password',
      loginLoading: false,
      hide: true
    }
  },

  created() {
    this.getCookie(flag => {
      if (flag) {
        this.onSubmit(this.form)
      } else {
        this.hide = false
      }
    })
  },

  methods: {
    getCookie(callback) {
      const username = Cookies.get('username')
      const password = Cookies.get('password')
      const rememberMe = Cookies.get('rememberMe')
      this.form = {
        username: username === undefined ? this.form.username : username,
        password: password === undefined ? this.form.password : decrypt(password),
        rememberMe: rememberMe === undefined ? this.form.rememberMe : rememberMe,
        validateCode: '',
      }

      callback(username && password)
    },

    // 提交
    onSubmit(form) {
      this.loginLoading = true
      if (this.tempCode === form.validateCode.toLowerCase()) {
        this.$store.dispatch('LoginByUserName', form).then(() => {
          // 存入cookie
          if (this.form.rememberMe) {
            Cookies.set('username', this.form.username, {expires: 30})
            Cookies.set('password', encrypt(this.form.password), {expires: 30})
            Cookies.set('rememberMe', this.form.rememberMe, {expires: 30})
          } else {
            Cookies.remove('username')
            Cookies.remove('password')
            Cookies.remove('rememberMe')
          }
          // 路由跳转
          this.$router.replace({path: 'index'})
        }).catch(err => {
          this.form.validateCode = ''
          this.key = new Date().getTime()
          Dialog.alert({
            message: err,
          })
          this.hide = false
        }).finally(() => {
          this.loginLoading = false
        })
      } else {
        Toast('验证码错误')
        this.form.validateCode = ''
        this.key = new Date().getTime()
        this.loginLoading = false
      }
    },
    // 点击查看密码
    onShowPwdIcon() {
      if (this.showPwdIcon === 'closed-eye') {
        this.showPwdIcon = 'eye-o'
        this.formType = 'text'
      } else {
        this.showPwdIcon = 'closed-eye'
        this.formType = 'password'
      }
    },

    // 刷新验证码
    refreshValidateCode(code) {
      this.tempCode = code
    },
  },
}
</script>

<style scoped>

.container {
  margin-top: 40px;
  text-align: center;
}

.login-body {
  margin: 20px;
}

.van-cell {
  align-items: baseline
}

/deep/ .van-checkbox__icon, /deep/ .van-checkbox__label {
  font-size: 14px;
}
</style>
