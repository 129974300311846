const updateLog = require('./logs.json')

// 根据实际情况修改此处配置
const clientele = '上海晨辉科技股份有限公司'
let config = {}

//配置信息针对不同客户做修改
switch (clientele) {
    case "上海晨辉科技股份有限公司":
        config = getConfig({})
        break
    case "杭州易造科技有限公司":
        config = getConfig({
            officialWebsite: 'https://www.eyzao.com/',
            contactWay: '0571-8972 1333',
            email: 'eyzao@eyzao.com',
            copyright: '杭州易造科技有限公司,2023 Eyzao.',
            address: '浙江省杭州市钱江世纪城广孚中心',
            netstormBootBaseUrl: 'http://124.71.173.40:8081/netstorm-boot/',
            appid: 'wxc37fc80700d947d1',
            domainName: 'http://www.spd1688.com/',
            tMapAk: 'ade3b8cdc1178b08393258b8515d1855',
        })
        break
}


function getConfig({
                       title, officialWebsite, contactWay, email, copyright, address,
                       netstormBootBaseUrl, appid, domainName, tMapAk
                   }) {
    return {
        title: title || '大气电场仪与雷电监测系统微信平台',
        // 版本
        version: updateLog.reverse()[0].version,
        // 官方网址
        officialWebsite: officialWebsite || 'http://www.shdawning.com/',
        // 电话号码
        contactWay: contactWay || '021-57606288',
        // 邮箱
        email: email || '13524358035@139.com',
        // 地址
        address: address || '上海市松江区车墩镇车安路208号',
        // 版权
        copyright: copyright || '上海晨辉科技股份有限公司,2023 Shdawning.',
        // netstorm-boot外网接口地址
        netstormBootBaseUrl: netstormBootBaseUrl || 'http://120.55.160.161:8081/netstorm-boot',
        // 微信公众号appid
        appid: appid || 'wx1af41e538a721db5',
        // 雷电预警微信公众号域名(网页授权回调地址)
        domainName: domainName || 'http://www.flytocare.com/',
        // 天地图key
        tMapAk: tMapAk || 'b71403517ac7a3bcf1a7084c8110dcc8',
    }
}

module.exports = config
