import login from '@/page/login/login'
import webpageAuth from '@/page/auth/webpageAuth'
import error404 from '@/components/error-page/404'
export default [
    {
        path: '/',
        redirect: login,
        meta:{
            keepAlive: false,
        }
    },
    {
        path: '/webpageAuth',
        name: 'webpageAuth',
        component: webpageAuth,
        meta:{
            keepAlive: false,
            title: '加载中...'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: {
            keepAlive: false,
            title: '登录',
        }
    },
    {
        path: '*',
        redirect: '/404',
        meta: {
            keepAlive: false,
            title: '404'
        }
    },
    {
        path: '/404',
        name: '404',
        component: error404,
        meta: {
            keepAlive: false,
            title: '404'
        }
    },
]
