/**
 * 获取url 参数
 * @param name
 * @returns {string|null}
 */
export const getUrlParam = name => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) {
        return unescape(r[2])
    }
    return null
}
