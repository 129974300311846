import {loginNetstormBoot} from "@/api/netstorm-boot";
import {getStore, setStore} from "@/util/store"
import Cookies from "js-cookie";
import {addUserBind} from "@/api/mine/bind";
import {alarmConfigSensorList} from "@/api/mine/alarmSensorConfig";

const user = {
    state: {
        userInfo: getStore({name: 'userInfo'}) || undefined,
        openId: getStore({name: 'openId'}) || '',
        apiToken: getStore({name: 'apiToken'}) || ''
    },

    actions: {
        LoginByUserName({commit}, loginInfo) {
            return new Promise(((resolve, reject) => {
                loginNetstormBoot(loginInfo.username, loginInfo.password).then(response => {
                    console.log(response)
                    if (response.data.code === 0) {
                        //登录成功
                        commit('SET_USER_INFO', response.data.data.userInfo)
                        // 存储
                        commit('SET_API_TOKEN', response.data.data.token)
                        // 添加用户绑定信息
                        const openId = user.state.openId
                        const userInfo = response.data.data.userInfo
                        addUserBind({openId: openId, userId: userInfo.userId}).then((res => {
                            const acId = res.data.data.acId
                            if (acId){
                                // 添加告警方案
                                alarmConfigSensorList(userInfo, openId, acId)
                            }
                        }))
                        resolve()
                    } else {
                        // 登录失败
                        reject(response.data.msg)
                    }
                }).catch(error => {
                    reject(error.toString())
                })

            }))
        },


        // 登出/注销
        Logout({commit}) {
            return new Promise(resolve => {
                commit('SET_USER_INFO', {})
                commit('SET_OPEN_ID', '')
                commit('SET_API_TOKEN', '')
                Cookies.remove('username')
                Cookies.remove('password')
                Cookies.remove('rememberMe')
                resolve()
            })
        }
    },

    mutations: {

        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo
            setStore({
                name: 'userInfo',
                content: state.userInfo,
                type: 'session'
            })
        },

        SET_OPEN_ID: (state, openId) => {
            state.openId = openId
            setStore({
                name: 'openId',
                content: state.openId,
                type: 'session'
            })
        },

        SET_API_TOKEN: (state, token) => {
            state.apiToken = token
            setStore({
                name: 'apiToken',
                content: state.apiToken,
            })
        }
    }
}

export default user
