<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
    <footer-tab-bar v-if="$route.meta.showTabbar"></footer-tab-bar>
  </div>
</template>

<script>
import FooterTabBar from "@/components/footer-tabbar/FooterTabBar";

export default {
  name: 'App',
  components: {
    FooterTabBar
  },
  data() {
    return {
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 65px;
}
</style>
