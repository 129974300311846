export default [
    /*我的->个人信息*/
    {
        path: '/userInfo',
        name: '个人信息',
        component: () => import ('@/views/mine/more/content/userInfo'),
        meta: {
            title: '个人信息',
            keepAlive: true,
            showTabbar: false
        },
    },
    /*我的->关于我们*/
    {
        path: '/aboutUs',
        name: '关于我们',
        component: () => import ('@/views/mine/more/content/aboutUs'),
        meta: {
            title: '关于我们',
            keepAlive: true,
            showTabbar: false
        },
    },
    /*我的->关于我们->更新日志*/
    {
        path: '/updLogs',
        name: '更新日志',
        component: () => import ('@/views/mine/more/content/updLogs'),
        meta: {
            title: '更新日志',
            keepAlive: true,
            showTabbar: false
        },
    },
    /*我的->设置*/
    {
        path: '/setting',
        name: '设置',
        component: () => import ('@/views/mine/more/setting'),
        meta: {
            title: '设置',
            keepAlive: true,
            showTabbar: false
        },
    },
    /*我的->设置->告警配置*/
    {
        path: '/alarmConfig',
        name: '告警配置',
        component: () => import ('@/views/mine/more/content/alarmConfig'),
        meta: {
            title: '告警配置',
            keepAlive: false,
            showTabbar: false
        },
    },
]
