const website = require('../const/website')
// 配置编译环境和线上环境之间的切换
const env = process.env

let netstormBootBaseUrl, appid, domainName, tMapAk

if (env.NODE_ENV === 'development') {
    // netstormBootBaseUrl = "http://192.168.1.2:8081/netstorm-boot"
    netstormBootBaseUrl = "http://120.55.160.161:8081/netstorm-boot"
    appid = 'wxc9f4b0696d2ce17a' // 测试号
    domainName = 'http://a3011.natapp1.cc/'
    tMapAk = 'b71403517ac7a3bcf1a7084c8110dcc8'
} else if (env.NODE_ENV === 'production') {
    netstormBootBaseUrl = website.netstormBootBaseUrl
    appid = website.appid
    domainName = website.domainName
    tMapAk = website.tMapAk
} else if (env.NODE_ENV === 'test') {
    // 忽略
}


module.exports = {
    netstormBootBaseUrl,
    appid,
    domainName,
    tMapAk,
}
