import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from '../config/NProgress'
import store from '../store'

import PageRouter from './page/index'
import TabbarRouter from './page/tabbar'
import AnalysisRouter from './page/analysis'
import MineRouter from './page/mine'
import {getStore} from "@/util/store";
import {validateIos} from "@/util/validate";

Vue.use(VueRouter)

let Router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
    routes: [...PageRouter, ...TabbarRouter, ...AnalysisRouter, ...MineRouter]
})

Router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    NProgress.start()
    if (to.meta.title) {
        document.title = to.meta.title || '首页'
    }

    let openId = getStore({name: 'openId'});

    if (!openId && to.path !== '/webpageAuth') {
        next('/webpageAuth')
    } else {
        let userInfo = store.getters.userInfo;
        if (openId && !userInfo && to.path !== '/login') {
            // NProgress.done()
            if (to.path !== '/webpageAuth') {
                next('/login')
            }
        } else {
            next()
        }
    }
})

Router.afterEach(() => {
    NProgress.done()
})

// 进行JSSDK通过 config 接口注入权限验证配置时
//安卓需要使用当前URL进行微信API注册（即当场调用location.href.split('#')[0]）
//iOS需要使用进入页面的初始URL进行注册，
// todo 此处代码有误
function updateIosLocation() {
    const url = location.href
    if (validateIos()) {
        if (window.entryUrl === '' || window.entryUrl === undefined || window.entryUrl === null) {
            window.entryUrl = url
        }
        if (window.entryUrl !== url) {
            window.entryUrl = url
            window.location.replace(url)
        }
    }
}

export default Router
