import request from "@/config/axios";


export function alarmConfigSensorList(userInfo, openId, acId){
    return request({
        url: '/mobile/wx/alarm/sensor/alarmConfigSensorList',
        method: 'get',
        params: {
            userInfo,
            openId,
            acId
        }
    })
}

export function updateAlarmSensorConfigPushState(obj){
    return request({
        url: '/mobile/wx/alarm/sensor/updateAlarmSensorConfigPushState',
        method: 'post',
        data: obj
    })
}
