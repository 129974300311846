import axios from 'axios'
import errorCode from '@/const/errorCode'
import {Dialog} from "vant";
import router from '@/router'
import store from '@/store'


// `timeout` 指定请求超时的毫秒数(0 表示无超时时间) 如果请求超过 `timeout` 的时间，请求将被中断
axios.defaults.timeout = 30000
//  `withCredentials` 表示跨域请求时是否需要使用凭证  跨域请求，允许保存cookie
axios.defaults.withCredentials = true

// HTTP-request拦截
axios.interceptors.request.use(config => {
    const isApiToken = (config.headers || {}).isApiToken === false
    let token = store.getters.apiToken
    if (token && !isApiToken) {
        config.headers['token'] = token// token
    }
    return config;
}, error => {
    return Promise.reject(error)
})

// HTTP-response拦截
axios.interceptors.response.use(res => {
    const status = Number(res.status) || 200
    const message = res.data.msg || errorCode[status] || errorCode['default']
    // 登录过期token过期
    if (res.data.code === -1){
        Dialog.alert({
            message: message,
        }).then(() => {
            store.dispatch('Logout').then(() => {
                router.push({ path: '/login' })
            })
        })
    }
    if (status !== 200 || res.data.code === 500 || res.data.code === 1) {
        Dialog.alert({
            message: message,
            type: 'error'
        })
        return Promise.reject(new Error(message))
    }

    return res
}, error => {
    console.log('axios=', error)
    return Promise.reject(error)
})

export default axios
