<template>
  <div class="error404">
    <p>404</p>
    <VanButton @click="goHome" size="large" style="width: 100px;" type="text">返回首页</VanButton>
    <VanButton @click="backPage" size="large" style="width: 100px;margin-left: 40px;" type="primary">返回上一页</VanButton>
  </div>
</template>

<script>
export default {
  name: "Error404",
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>
<style scoped>
.error404{
  margin: 150px;
}
</style>
