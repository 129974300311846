export default [
    {
        path: '/index',
        name: '首页',
        component: () => import ('@/views/home/home'),
        meta: {
            title: '首页',
            keepAlive: true,
            showTabbar: true
        },
    },
    {
        path: '/analysis',
        name: '查询',
        component: () => import ('@/views/analysis/analysis'),
        meta: {
            title: '查询',
            keepAlive: true,
            showTabbar: true
        }
    },
    {
        path: '/mine',
        name: '我的',
        component: () => import ('@/views/mine/mine'),
        meta: {
            title: '我的',
            keepAlive: true,
            showTabbar: true
        }
    },
    /*首页->实时数据*/
    {
        path: '/realTime_detail',
        name: '实时数据',
        component: () => import ('@/views/home/children/detail'),
        meta: {
            title: '实时数据',
            keepAlive: false,
            showTabbar: false
        },
    },
]
