<template>
    <van-tabbar route>
      <van-tabbar-item replace icon="home-o" to="/index">首页</van-tabbar-item>
      <van-tabbar-item replace icon="desktop-o" to="/analysis">查询</van-tabbar-item>
<!--      <van-tabbar-item replace icon="records" to="/report">报告</van-tabbar-item>-->
<!--      <van-tabbar-item replace icon="bullhorn-o" to="/alarm">告警</van-tabbar-item>-->
      <van-tabbar-item replace icon="contact" to="/mine">我的</van-tabbar-item>
    </van-tabbar>
</template>

<script>
import {Tabbar, TabbarItem} from "vant";

export default {
  name: "FooterTabBar",
  components:{
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data(){
    return {
      name: 'report'
    }
  }
}
</script>

<style scoped>
</style>
