import request from "@/config/axios";

export function getUserBind(params){
    return request({
        url: '/mobile/wx/user/bind/getUserBind',
        method: 'get',
        params
    })
}

export function addUserBind(obj){
    return request({
        url: '/mobile/wx/user/bind/add',
        method: 'post',
        data: obj
    })
}

export function updateUserBind(obj){
    return request({
        url: '/mobile/wx/user/bind/update',
        method: 'post',
        data: obj
    })
}
