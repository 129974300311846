<template>
  <div></div>
</template>

<script>
import {appid, domainName} from "@/config/env";
import {getUrlParam} from '@/util/url'
import {wxOAuth2UserInfo} from "@/api/auth/auth";
import wx from "weixin-js-sdk"
import {getStore, setStore} from "@/util/store";


export default {
  name: "webpageAuth",
  data() {
    return {
      appId: appid, // 公众号的唯一标识
      redirectUrl: encodeURIComponent(`${domainName}#/webpageAuth`), // 授权后重定向的回调链接地址， 请使用 urlEncode 对链接进行处理
      state: '' // 重定向后会带上 state 参数，开发者可以填写a-zA-Z0-9的参数值，最多128字节
    }
  },
  created() {
    // 微信端授权重定向地址后携带code参数
    let code = getUrlParam('code');
    if (code) {
      this.getWxUserInfo(code)
      return
    }
    // 已经授权此时直接退出
    if (getStore({name: 'openId'})) {
      setTimeout(() => {
        wx.closeWindow()
      }, 1000)
    } else {
      // 去微信授权
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${this.redirectUrl}&response_type=code&scope=snsapi_userinfo&state=${this.state}#wechat_redirect`
    }
  },
  methods: {
    getWxUserInfo(code){
        // 去用code获取用户信息
        wxOAuth2UserInfo({appId: this.appId, code: code}).then(resp => {
          let wxAuth2UserInfo = resp.data.data
          this.$store.commit('SET_OPEN_ID', wxAuth2UserInfo.openid)
        }).finally(() => {
          this.removeRedundancyParam()
        })
    },

    removeRedundancyParam(){
      let Wechat_return_code = window.location.href
      if (Wechat_return_code.indexOf("?") !== -1) {
        Wechat_return_code = Wechat_return_code.replace(/([?#])[^'"]*/, "");
        window.history.replaceState({}, null, Wechat_return_code);
        this.$router.replace({path: '/login'})
      }
    }
  },
}
</script>

<style scoped>

</style>
