import request from '@/config/axios'

export function wxOAuth2UserInfo(params) {
    return request({
        url: '/mobile/wx/open/auth/webpage/userInfo',
        method: 'get',
        params: params
    })
}

export function getJsapiSignature(params){
    return request({
        url: '/mobile/wx/open/auth/jsapi/jsapiSignature',
        method: 'get',
        params: params
    })
}
