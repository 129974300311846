export default [
    /*查询->预警事件->列表查询*/
    {
        path: '/analysis_event_list',
        name: '预警事件统计',
        component: () => import ('@/views/analysis/detail/event/list'),
        meta: {
            title: '预警事件统计',
            keepAlive: true,
            showTabbar: false
        },
    },
    /*查询->预警事件->详细预警事件*/
    {
        path: '/analysis_event_events',
        name: '预警事件详情',
        component: () => import ('@/views/analysis/detail/event/events'),
        meta: {
            title: '预警事件详情',
            keepAlive: false,
            showTabbar: false
        },
    },
    /*查询->场强数据->列表查询*/
    {
        path: '/analysis_field_list',
        name: '站点信息',
        component: () => import ('@/views/analysis/detail/field/list'),
        meta: {
            title: '站点信息',
            keepAlive: true,
            showTabbar: false
        },
    },
    /*查询->场强数据->历史数据及图表分析*/
    {
        path: '/analysis_field_history',
        name: '场强数据',
        component: () => import ('@/views/analysis/detail/field/history'),
        meta: {
            title: '场强数据',
            keepAlive: false,
            showTabbar: false
        },
    },
    /*查询->六要素->列表查询*/
    {
        path: '/analysis_sixElem_list',
        name: '六要素',
        component: () => import ('@/views/analysis/detail/sixElem/list'),
        meta: {
            title: '六要素',
            keepAlive: true,
            showTabbar: false
        },
    },
    /*查询->六要素->历史数据及图表分析*/
    {
        path: '/analysis_sixElem_history',
        name: '六要素数据',
        component: () => import ('@/views/analysis/detail/sixElem/history'),
        meta: {
            title: '六要素数据',
            keepAlive: false,
            showTabbar: false
        },
    },
]

