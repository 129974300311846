import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqKfy9sND7UMxrtbbLF2S\n' +
    'M0haFCqqb8hELqCUyUJMGpjP0EkZMkF2GJD8oGzigMYek4/SnmZPrQDLqFxaAxcX\n' +
    'WEQ0QcsrYAEp521iVwtwvj0JaaISJfMioyYh+xO8UBm5xI5bhfbyiYE+YQ7u47M7\n' +
    'Ja5g0fgNGsQ9XzOB/ox33XvmE070CY+kv2/BAmUY6e2V61mhUzrdDSG9RCPLjREg\n' +
    'Zbqh9LaTv/bHzlVq/ssMKRQG+qkctY5suEoXi7aqzz+PCqMDJImEGmMlUW99PkHo\n' +
    '4mu0ZPCGfoGAY+++tB8KJOoYLZ/MGzk9/ZlFQw20xgVHpdHhQE+BsANg6ZZP5GM/\n' +
    'hwIDAQAB'

const privateKey = 'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQCop/L2w0PtQzGu\n' +
    '1tssXZIzSFoUKqpvyEQuoJTJQkwamM/QSRkyQXYYkPygbOKAxh6Tj9KeZk+tAMuo\n' +
    'XFoDFxdYRDRByytgASnnbWJXC3C+PQlpohIl8yKjJiH7E7xQGbnEjluF9vKJgT5h\n' +
    'Du7jszslrmDR+A0axD1fM4H+jHfde+YTTvQJj6S/b8ECZRjp7ZXrWaFTOt0NIb1E\n' +
    'I8uNESBluqH0tpO/9sfOVWr+ywwpFAb6qRy1jmy4SheLtqrPP48KowMkiYQaYyVR\n' +
    'b30+Qejia7Rk8IZ+gYBj7760Hwok6hgtn8wbOT39mUVDDbTGBUel0eFAT4GwA2Dp\n' +
    'lk/kYz+HAgMBAAECggEBAJPmDANnQucKmMQI+FSUjcPY+91ZePLjvR7ihPdk4xYj\n' +
    '0YGGyMiwbR6n7lHEinCAzIl8gyeS8uX0n6O5sNmq4zbtQKI9x4C+JIAlBSruBmVd\n' +
    'Yya2EJIk/8fwhbYHHYlktepvKEV3RENfClPU45rcLs8hdxhKHTvOwT7+DU4q4mZf\n' +
    'doHWBnsEQNK0HfRfdTMJni9f/4GGUEkZZ4wIIWQE1kTsbq6VwYnmjPDLFHCpOZFZ\n' +
    'klacB5C+21cWdTlaKsbTcked5ljarDP/h1R9siKoSDDg9DCWjP+mR4Csf4PWR4no\n' +
    'Z31n2ybaXZEVjQAG1Ton9MZtuIcUnPQ8eP1XUSRPDzECgYEA2pJxGOOhKv4Rvt5g\n' +
    '47M8aRpgkuu9kKsyjiBi8AvuHgafHOzqANJm8Z4Ai8N5AH6hQ3lGaqmiYaZuCV2N\n' +
    'D0mOsMhObZun4sk1AIXcBh36fpVn+riQ1c/qZ7pgEKcaMD2hHFJNhEmtBRMmVdyb\n' +
    '1c55XCgJ1oS5K2wNUnTH/Y3rXxkCgYEAxYlWKN0//N3wKfO99qve8pxEcYNFwKnS\n' +
    'Na+LHOvghE/+tvSdznHssJVCe600JX+3Zzs1fcL3NciQIN817GRreg1bjjHlNhQS\n' +
    'PWIvMGf4k756FnzYpbgBcMQ7/CTuYshSmoUC0fJsa5wM9tT/89YDS1FMH+WHigtZ\n' +
    'GFUiNssVh58CgYEAvBpFbOcRn99RxFK7TZrGBanmrOpmlOdQtdIXc4DA52RJcOpP\n' +
    'JG/umkehLqcGLq/L9zaarFba4XEKx9KUADEMtQVdwaSw4lLI429QAI+yMVhBSRun\n' +
    'weqST7ysyggVWKjhS29lONDzLyfg2QnnryRnezEV7mEAvubZvDm2l3aYtpkCgYEA\n' +
    'tVFGoOPVZEpjtF5jNBS25ILD4F3anOpEZbroOnfY8hprd4w5fdHcrImVKOjnQLRm\n' +
    'qH8UrodTWy7p/QJmAMJ8VOAF9hOejXEG/Qkuv4PwC81/Szz1r8eiqtu1I9DScCHo\n' +
    'kKUe3Y9kLbJjw/3nou4CwBOziGOrce7QrSEX8que64kCgYAEL8qTWkpaY/KgOmPz\n' +
    'xa0I0H/b9jY1Yb0l5GyszzvTqPCGbEWSYPmZelIQ0VrpYHmw7mCRbthlyhkhxrbf\n' +
    'vAvMAbrwKXoijhj2/I6P1g2Ng9xwhvKHOsSRnRxYrEbYqFPjfplIRwZnJ82N863h\n' +
    'k10nWXgq+H8Y3NRzH/EGfWEOHQ=='


// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

