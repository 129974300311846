import request from '../config/axios.js'

export function loginNetstormBoot(username, password) {
    return request({
        url: `/netstorm-boot/wx-api/user/auth`,
        method: 'post',
        headers: {
            isApiToken: false
        },
        data: {
            loginName: username,
            userPsw: password,
        }
    })
}

export function getSensorList(userInfo){
    return request({
        url: `/netstorm-boot/wx-api/data/sensorList`,
        method: 'get',
        params: {
            userInfo: userInfo
        }
    })
}

export function getLatelyFieldData(params){
    return request({
        url: `/netstorm-boot/wx-api/data/envMonitorData`,
        method: 'get',
        params
    })
}

export function getSelectSensorList(userInfo){
    return request({
        url: `/netstorm-boot/wx-api/data/selectSensorList`,
        method: 'get',
        params: {
            userInfo: userInfo
        }
    })
}

export function selectEventStat(params){
    return request({
        url: `/netstorm-boot/wx-api/data/selectEventStat`,
        method: 'get',
        params
    })
}

export function sensorEventPage(params){
    return request({
        url: `/netstorm-boot/wx-api/data/sensorEventsPage`,
        method: 'get',
        params
    })
}

export function selectFields(params){
    return request({
        url: `/netstorm-boot/wx-api/data/selectFields`,
        method: 'get',
        params
    })
}

export function selectSixElements(params) {
    return request({
        url: `/netstorm-boot/wx-api/data/selectSixElems`,
        method: 'get',
        params
    })
}

export function selectLightningData(params) {
    return request({
        url: `/netstorm-boot/wx-api/data/selectLightningData`,
        method: 'get',
        params
    })
}
